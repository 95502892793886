import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql } from "gatsby";
import { convertHero, ResourceCards } from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ResourcesLandingPageTemplate = ({ data }) => {
  const resourcesData = data?.craftAPI?.entry;

  const children = convertComponentList(resourcesData?.componentList);

  const resourceChildren = resourcesData?.children;

  children.unshift(<ResourceCards data={resourceChildren} />);

  // Place the hero at the top of the detail
  children.unshift(convertHero(resourcesData));

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={resourcesData.coverImage}
        pageType={PageTypes.RESOURCES}
        shortDescription={resourcesData.shortDescription}
        theme={Theme.Black}
        title={resourcesData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const resourcesQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        id
        ... on CraftAPI_resources_resources_Entry {
          coverImage {
            ...ImageMetadataFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          heroType
          id
          longDescription
          shortDescription
          title
          componentList {
            ...ComponentListFragment
          }
        }
        children {
          ... on CraftAPI_resources_resourcesTopic_Entry {
            id
            buttonColor
            buttonText
            coverImage {
              ...ImageMetadataFragment
            }
            coverImageCropStyle
            coverImagePositionOverride
            fontType
            heroIcon
            longDescription
            title
            uri
          }
        }
      }
    }
  }
`;

ResourcesLandingPageTemplate.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export default ResourcesLandingPageTemplate;
